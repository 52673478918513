<app-spinner *ngIf="isLoading; else content"></app-spinner>

<ng-template #content>
<div class="row">
  <div class="col-sm-12 col-md-12 mx-auto">
    <div class="card">
      <div class="card-body">
        <h3 class="text-center">Nuevo Usuario</h3>
        <form [formGroup]="form" (ngSubmit)="onEdit()">
          <mat-form-field class="full-width-input">
            <input
              type="text"
              formControlName="name"
              matInput
              placeholder="Nombre"
              required
            />
            <mat-error *ngIf="isValidField('name')">
              {{ getErrorMessage("name") }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input
              type="text"
              formControlName="last_name"
              matInput
              placeholder="Apellido"
              required
            />
            <mat-error *ngIf="isValidField('last_name')">
              {{ getErrorMessage("last_name") }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input
              type="email"
              formControlName="email"
              matInput
              placeholder="Correo"
              required
            />
            <mat-error *ngIf="isValidField('email')">
              {{ getErrorMessage("email") }}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="full-width-input">
            <mat-label>Ubicación</mat-label>
            <mat-select formControlName="location_id">
              <mat-option *ngFor="let item of lstLocations" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width-input">
            <input
              type="password"
              formControlName="password"
              matInput
              placeholder="Contraseña"
            />
            <mat-error *ngIf="isValidField('password')">
              {{ getErrorMessage("password") }}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="full-width-input">
            <mat-label>Rol</mat-label>
            <mat-select formControlName="role_id">
              <mat-option *ngFor="let item of lstRoles" [value]="item.id">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="full-width-input">
            <input type="checkbox" formControlName="state">  Estado
          </div>

          <button type="submit" class="btn btn-primary btn-block mt-3">
            Guardar
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
</ng-template>
