import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { InventarioService } from 'src/app/services/inventarioService/inventario.service';

@Component({
  selector: 'app-report-batch',
  templateUrl: './report-batch.component.html',
  styleUrls: ['./report-batch.component.scss'],
})
export class ReportBatchComponent implements OnInit {
  isLoading = true;

  current;
  lstItems: any = [];

  displayedColumns: string[] = [
    'item',
    'state',
    'location',
    'user',
    'date_time',
    'options',
  ];
  dataSource = new MatTableDataSource();
  dataSource2 = new MatTableDataSource();

  applyFilter(filterValue: any) {
    this.dataSource.filter = filterValue.target.value.trim().toLowerCase();
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ws: InventarioService
  ) {}

  async ngOnInit(): Promise<void> {
    let id = this.route.snapshot.params.id;

    const branch = await new Promise<any>((resolve) =>
      this.ws.getBatch(id).subscribe((translated) => {
        resolve(translated);
      })
    );

    console.log(branch);

    const datos = await new Promise<any>((resolve) =>
      this.ws.getBatchsItems(id).subscribe((translated) => {
        resolve(translated);
      })
    );

    this.current = branch.res;
    this.lstItems = datos.res;

    /* datos.res filter in this.dataSource.data */
    this.dataSource.data = datos.res.filter((item) => {
      return item.existe;
    });
    this.dataSource2.data = datos.res.filter((item) => {
      return !item.existe;
    });

    this.isLoading = false;
  }

  onShow(item: any) {
    this.router.navigate(['admin/items/show/' + item.id]);
  }

  onShowRegister(item: any) {
    this.router.navigate(['admin/items/showRegister/' + item.id]);
  }
}
