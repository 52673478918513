import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { InventarioService } from 'src/app/services/inventarioService/inventario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {

  isLoading = true;
  displayedColumns: string[] = [
    'item',
    'options',
  ];
  dataSource = new MatTableDataSource();

  constructor(
    private router: Router,
    private ws: InventarioService,
    public dialog: MatDialog
  ) {}

  applyFilter(filterValue: any) {
    this.dataSource.filter = filterValue.target.value.trim().toLowerCase();
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  async ngOnInit() {
    const datos = await new Promise<any>((resolve) =>
      this.ws.getItems().subscribe((translated) => {
        resolve(translated);
      })
    );

    if (datos.success) {
      this.dataSource.data = datos.res;
      this.isLoading = false;
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  hideSpinner() {
    this.isLoading = false;
  }

  onNewItem() {
    this.router.navigate(['admin/items/new']);
  }

  onShow(item: any) {
    this.router.navigate(['admin/items/show/' + item.id]);
  }

  onShowRegister(item: any) {
    this.router.navigate(['admin/items/showRegister/' + item.id]);
  }

  onEditItem(item: any) {
    this.router.navigate(['admin/items/edit/' + item.id]);
  }

/*   onDeleteItem(item: any) {
    Swal.fire({
      title: '¿Estás seguro de eliminar?',
      text: 'Una vez eliminado, no se puede recuperar',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Eliminar',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
    }).then(async (result: any) => {
      if (result.value) {
        const datos = await new Promise<any>((resolve) =>
          this.ws.deleteItem(item.id).subscribe((translated) => {
            resolve(translated);
          })
        );

        if (datos.success) {
          Swal.fire('Removed', 'Usuario eliminado', 'success');
          this.ngOnInit();
        } else {
          Swal.fire('Error', 'Usuario no eliminado', 'warning');
        }
      }
    });
  } */
}
