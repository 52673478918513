import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { InventarioService } from 'src/app/services/inventarioService/inventario.service';

@Component({
  selector: 'app-show-register',
  templateUrl: './show-register.component.html',
  styleUrls: ['./show-register.component.scss']
})
export class ShowRegisterComponent implements OnInit {

  isLoading = true;
  displayedColumns: string[] = [
    'state',
    'location',
    'user',
    'date_time',

  ];
  dataSource = new MatTableDataSource();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ws: InventarioService,
    public dialog: MatDialog
  ) {}

  applyFilter(filterValue: any) {
    this.dataSource.filter = filterValue.target.value.trim().toLowerCase();
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  async ngOnInit() {
    let id = this.route.snapshot.params.id;
    const datos = await new Promise<any>((resolve) =>
      this.ws.getRegistersItem(id).subscribe((translated) => {
        resolve(translated);
      })
    );

    if (datos.success) {
      this.dataSource.data = datos.res;
    }
    this.isLoading = false;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  hideSpinner() {
    this.isLoading = false;
  }
}
