import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { InventarioService } from 'src/app/services/inventarioService/inventario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  isLoading = true;
  displayedColumns: string[] = [
    'id',
    'name',
    'description',
    'price',
    /* 'state', */
    'options',
  ];
  dataSource = new MatTableDataSource();

  constructor(
    private router: Router,
    private ws: InventarioService,
    public dialog: MatDialog
  ) {}

  applyFilter(filterValue: any) {
    this.dataSource.filter = filterValue.target.value.trim().toLowerCase();
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  async ngOnInit() {
    const datos = await new Promise<any>((resolve) =>
      this.ws.getProducts().subscribe((translated) => {
        resolve(translated);
      })
    );

    if (datos.success) {
      this.dataSource.data = datos.res;
      this.isLoading = false;
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  hideSpinner() {
    this.isLoading = false;
  }

  onNew() {
    this.router.navigate(['admin/products/new']);
  }

  onEdit(product: any) {
    this.router.navigate(['admin/products/edit/' + product.id]);
  }

  onDelete(product: any) {
    Swal.fire({
      title: '¿Estás seguro de eliminar?',
      text: 'Una vez eliminado, no se puede recuperar',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Eliminar',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
    }).then(async (result: any) => {
      if (result.value) {
        const datos = await new Promise<any>((resolve) =>
          this.ws.deleteProduct(product.id).subscribe((translated) => {
            resolve(translated);
          })
        );

        if (datos.success) {
          Swal.fire('Eliminar', 'Se eliminó de forma correcta', 'success');
          this.ngOnInit();
        } else {
          Swal.fire('Eliminar', 'Error', 'warning');
        }
      }
    });
  }
}
