<mat-sidenav-container>

  <mat-sidenav #sidenav="matSidenav" class="mat-elevation-z8">

    <img class="avatar mat-elevation-z8" src="../../../assets/avatar.jpg" alt="avatar">

    <h4 class="name">{{ userData.name ? userData.name : "" }}</h4>
    <p class="designation">{{ role.name ? role.name : ""}}</p>

    <mat-divider></mat-divider>

    <div *ngIf="role.id === 1">
      <button routerLink="/admin/users" mat-button class="menu-button">
        <mat-icon>article</mat-icon>
        <span>Usuarios</span>
      </button>
      <button routerLink="/admin/products" mat-button class="menu-button">
        <mat-icon>article</mat-icon>
        <span>Productos</span>
      </button>
      <button routerLink="/admin/warehouses" mat-button class="menu-button">
        <mat-icon>article</mat-icon>
        <span>Bodegas</span>
      </button>
      <button routerLink="/admin/batchs" mat-button class="menu-button">
        <mat-icon>article</mat-icon>
        <span>Lotes</span>
      </button>
      <button routerLink="/admin/batchs/inactive" mat-button class="menu-button">
        <mat-icon>article</mat-icon>
        <span>Lotes Inactivos</span>
      </button>
      <button routerLink="/admin/items" mat-button class="menu-button">
        <mat-icon>article</mat-icon>
        <span>Articulos</span>
      </button>
      <button routerLink="/admin/sales" mat-button class="menu-button">
        <mat-icon>article</mat-icon>
        <span>Ventas</span>
      </button>
      <button routerLink="/admin/payments" mat-button class="menu-button">
        <mat-icon>article</mat-icon>
        <span>Abonos</span>
      </button>
    </div>

    <div *ngIf="role.id === 2">
      <button routerLink="/admin/batchs" mat-button class="menu-button">
        <mat-icon>article</mat-icon>
        <span>Lotes</span>
      </button>
      <button routerLink="/admin/items" mat-button class="menu-button">
        <mat-icon>article</mat-icon>
        <span>Articulos</span>
      </button>
      <button routerLink="/admin/sales" mat-button class="menu-button">
        <mat-icon>article</mat-icon>
        <span>Ventas</span>
      </button>
      <button routerLink="/admin/payments" mat-button class="menu-button">
        <mat-icon>article</mat-icon>
        <span>Abonos</span>
      </button>
    </div>

    <mat-divider></mat-divider>

    <button mat-button class="menu-button">
      <mat-icon>help</mat-icon>
      <span>Ayuda</span>
    </button>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar class="mat-elevation-z8">
      <button mat-icon-button *ngIf="sidenav.mode === 'over'" (click)="sidenav.toggle()">
        <mat-icon *ngIf="!sidenav.opened">
          menu
        </mat-icon>
        <mat-icon *ngIf="sidenav.opened">
          close
        </mat-icon>
      </button>
      <span>
        <a routerLink="/admin">
          Sistema de Inventario
        </a>
      </span>
      <span class="example-spacer"></span>
      <button mat-button (click)="closeSesion()">
        Cerrar sesión
      </button>
    </mat-toolbar>

    <div class="content mat-elevation-z8">
      <div class="container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>

</mat-sidenav-container>
