import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { InventarioService } from 'src/app/services/inventarioService/inventario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.scss'],
})
export class NewProductComponent implements OnInit {
  public productForm;

  isLoading = false;

  constructor(
    private router: Router,
    private ws: InventarioService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.productForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      price: new FormControl('', [Validators.required]),
      state: new FormControl(true),
    });
  }

  async onCreate() {
    if (this.productForm.valid) {
      let data = this.productForm.value;
      data['fecha'] = new Date();
      const datos = await new Promise<any>((resolve) =>
        this.ws.postProduct(data).subscribe((translated) => {
          resolve(translated);
        })
      );

      if (datos.success) {
        this.router.navigate(['admin/products']);
      } else {
        Swal.fire({
          title: 'Guardar',
          text: 'Error al guardar',
          icon: 'error',
        });
      }
    } else {
      Swal.fire('Guardar', 'Llene todos los campos', 'info');
    }
  }

  getErrorMessage(field: string): string {
    let message;
    if (this.productForm.get(field).errors.required) {
      message = 'Debes ingresar un valor';
    } else if (this.productForm.get(field).hasError('email')) {
      message = 'No es un email valido';
    } else if (this.productForm.get(field).hasError('minlength')) {
      message = 'Este campo debe tener más de 6 caracteres';
    }
    return message;
  }

  isValidField(field: string): boolean {
    return (
      (this.productForm.get(field).touched ||
        this.productForm.get(field).dirty) &&
      !this.productForm.get(field).valid
    );
  }
}
