<app-spinner *ngIf="isLoading; else content"></app-spinner>

<ng-template #content>
<div class="row">
  <div class="col-sm-12 col-md-12 mx-auto">
    <div class="card">
      <div class="card-body">
        <h3 class="text-center">Nuevo Producto</h3>
        <form [formGroup]="productForm" (ngSubmit)="onCreate()">
          <mat-form-field class="full-width-input">
            <input
              type="text"
              formControlName="name"
              matInput
              placeholder="Nombre"
              required
            />
            <mat-error *ngIf="isValidField('name')">
              {{ getErrorMessage("name") }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input
              type="text"
              formControlName="description"
              matInput
              placeholder="Descripción"
              required
            />
            <mat-error *ngIf="isValidField('description')">
              {{ getErrorMessage("description") }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width-input">
            <input
              type="number"
              formControlName="price"
              matInput
              placeholder="Precio"
              required
            />
            <mat-error *ngIf="isValidField('price')">
              {{ getErrorMessage("price") }}
            </mat-error>
          </mat-form-field>

          <!-- <div class="full-width-input">
            <input type="checkbox" formControlName="state">  Estado
          </div> -->

          <button type="submit" class="btn btn-primary btn-block mt-3">
            Guardar
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
</ng-template>
