import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InventarioService } from 'src/app/services/inventarioService/inventario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-sale',
  templateUrl: './edit-sale.component.html',
  styleUrls: ['./edit-sale.component.scss'],
})
export class EditSaleComponent implements OnInit {
  public current;
  public payments = [];

  isLoading = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ws: InventarioService
  ) {}

  async ngOnInit(): Promise<void> {
    const id = this.route.snapshot.params.id;
    const datos: any = await this.ws.getSale(id);
    this.current = datos.res;

    const payments: any = await this.ws.getSalePayments(id);
    this.payments = payments.res;
    this.isLoading = false;
  }

  async edit() {
    const response:any = await this.ws.setSalePayments(
      this.current.id,
      this.payments
    );
    if (response.success) {
      this.router.navigate(['admin/sales']);
    } else {
      Swal.fire({
        title: 'Guardar',
        text: 'Error al guardar',
        icon: 'error',
      });
    }
  }
}
