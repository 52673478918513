import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InventarioService } from 'src/app/services/inventarioService/inventario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-payment',
  templateUrl: './new-payment.component.html',
  styleUrls: ['./new-payment.component.scss'],
})
export class NewPaymentComponent implements OnInit {
  public form;
  public lstLocations;

  isLoading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ws: InventarioService
  ) {}

  async ngOnInit(): Promise<void> {
    let id = this.route.snapshot.params.id;

    this.form = new FormGroup({
      sale_id: new FormControl(id, Validators.required),
      bank: new FormControl('', [Validators.required]),
      amount: new FormControl('', [Validators.required]),
      state: new FormControl(true),
    });

    const datos = await new Promise<any>((resolve) =>
      this.ws.getLocations().subscribe((translated) => {
        resolve(translated);
      })
    );

    if (datos.success) {
      this.lstLocations = datos.res;
    }
  }

  async onCreate() {
    let id = this.route.snapshot.params.id;

    if (this.form.valid) {
      let data = this.form.value;
      data['fecha'] = new Date();
      const datos = await new Promise<any>((resolve) =>
        this.ws.postPayment(data).subscribe((translated) => {
          resolve(translated);
        })
      );

      if (datos.success) {
        this.router.navigate(['admin/sales/payments/' + id]);
      } else {
        Swal.fire({
          title: 'Guardar',
          text: 'Error al guardar',
          icon: 'error',
        });
      }
    } else {
      Swal.fire('Guardar', 'Llene todos los campos', 'info');
    }
  }

  getErrorMessage(field: string): string {
    let message;
    if (this.form.get(field).errors.required) {
      message = 'Debes ingresar un valor';
    } else if (this.form.get(field).hasError('email')) {
      message = 'No es un email valido';
    } else if (this.form.get(field).hasError('minlength')) {
      message = 'Este campo debe tener más de 6 caracteres';
    }
    return message;
  }

  isValidField(field: string): boolean {
    return (
      (this.form.get(field).touched || this.form.get(field).dirty) &&
      !this.form.get(field).valid
    );
  }
}
