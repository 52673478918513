import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/auch/login/login.component';
import { BatchsInactiveComponent } from './components/batchs-inactive/batchs-inactive.component';
import { BatchsComponent } from './components/batchs/batchs.component';
import { EditBatchComponent } from './components/batchs/edit-batch/edit-batch.component';
import { NewBatchComponent } from './components/batchs/new-batch/new-batch.component';
import { QrPrintComponent } from './components/batchs/qr-print/qr-print.component';
import { ReportBatchComponent } from './components/batchs/report-batch/report-batch.component';
import { ContainerAdminComponent } from './components/container-admin/container-admin.component';
import { EditItemComponent } from './components/items/edit-item/edit-item.component';
import { ItemsComponent } from './components/items/items.component';
import { NewItemComponent } from './components/items/new-item/new-item.component';
import { ShowItemComponent } from './components/items/show-item/show-item.component';
import { ShowRegisterComponent } from './components/items/show-register/show-register.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { EditProductComponent } from './components/products/edit-product/edit-product.component';
import { NewProductComponent } from './components/products/new-product/new-product.component';
import { ProductsComponent } from './components/products/products.component';
import { EditSaleComponent } from './components/sales/edit-sale/edit-sale.component';
import { EditPaymentComponent } from './components/sales/sale-payments/edit-payment/edit-payment.component';
import { NewPaymentComponent } from './components/sales/sale-payments/new-payment/new-payment.component';
import { SalePaymentsComponent } from './components/sales/sale-payments/sale-payments.component';
import { SalesComponent } from './components/sales/sales.component';
import { EditUserComponent } from './components/users/edit-user/edit-user.component';
import { NewUserComponent } from './components/users/new-user/new-user.component';
import { UsersComponent } from './components/users/users.component';
import { EditWarehouseComponent } from './components/warehouses/edit-warehouse/edit-warehouse.component';
import { NewWarehouseComponent } from './components/warehouses/new-warehouse/new-warehouse.component';
import { WarehousesComponent } from './components/warehouses/warehouses.component';
import { LoginGuard } from './guard/login.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'admin',
    component: ContainerAdminComponent,
    canActivate: [LoginGuard],
    children: [
      {
        path: 'users',
        component: UsersComponent,
      },
      {
        path: 'users/new',
        component: NewUserComponent,
      },
      {
        path: 'users/edit/:id',
        component: EditUserComponent,
      },
      {
        path: 'products',
        component: ProductsComponent,
      },
      {
        path: 'products/new',
        component: NewProductComponent,
      },
      {
        path: 'products/edit/:id',
        component: EditProductComponent,
      },
      {
        path: 'warehouses',
        component: WarehousesComponent,
      },
      {
        path: 'warehouses/new',
        component: NewWarehouseComponent,
      },
      {
        path: 'warehouses/edit/:id',
        component: EditWarehouseComponent,
      },
      {
        path: 'batchs',
        component: BatchsComponent,
      },
      {
        path: 'batchs/inactive',
        component: BatchsInactiveComponent,
      },
      {
        path: 'batchs/new',
        component: NewBatchComponent,
      },
      {
        path: 'batchs/edit/:id',
        component: EditBatchComponent,
      },
      {
        path: 'batchs/report/:id',
        component: ReportBatchComponent
      },
      {
        path: 'batchs/qr/:id',
        component: QrPrintComponent,
      },
      {
        path: 'items',
        component: ItemsComponent,
      },
      {
        path: 'items/new',
        component: NewItemComponent,
      },
      {
        path: 'items/edit/:id',
        component: EditItemComponent,
      },
      {
        path: 'items/show/:id',
        component: ShowItemComponent,
      },
      {
        path: 'items/showRegister/:id',
        component: ShowRegisterComponent,
      },
      {
        path: 'sales',
        component: SalesComponent,
      },
      {
        path: 'sales/edit/:id',
        component: EditSaleComponent,
      },
      {
        path: 'sales/payments/:id',
        component: SalePaymentsComponent,
      },
      {
        path: 'sales/payment/new/:id',
        component: NewPaymentComponent,
      },
      {
        path: 'sales/payment/edit/:id',
        component: EditPaymentComponent,
      },
      {
        path: 'payments',
        component: PaymentsComponent,
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
