<app-spinner *ngIf="isLoading"></app-spinner>

<div [hidden]="isLoading">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Articulos que llegaron a su destino
      </mat-panel-title>
      <mat-panel-description>
        {{ 'Total: ' + dataSource.data.length + '/' + lstItems.length }}
      </mat-panel-description>
    </mat-expansion-panel-header>

    <!-- <mat-form-field>
    <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" />
  </mat-form-field> -->

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
      <ng-container matColumnDef="item">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Articulo</th>
        <td mat-cell *matCellDef="let element">{{ current.product.name + " (Lote: " + current.number + ", Articulo: " +
          element.number + ")"}}</td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
        <td mat-cell *matCellDef="let element">{{ element.register?.state.name }}</td>
      </ng-container>

      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ubicación</th>
        <td mat-cell *matCellDef="let element">{{ element.register?.location.name}}</td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
        <td mat-cell *matCellDef="let element">{{ element.register?.user.name }}</td>
      </ng-container>

      <ng-container matColumnDef="date_time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
        <td mat-cell *matCellDef="let element">{{ element.register?.date_time | date :'yyyy-dd-MM hh-mm-ss' }}</td>
      </ng-container>

      <ng-container matColumnDef="options">

        <th mat-header-cell *matHeaderCellDef>Opciones</th>
        <td mat-cell *matCellDef="let element">
          <button mat-stroked-button color="accent" (click)="onShow(element)">
            <mat-icon>qr_code_2</mat-icon>
          </button>
          <button mat-stroked-button color="prym" (click)="onShowRegister(element)">
            <mat-icon>segment</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <!-- <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator> -->
  </mat-expansion-panel>

  <br>
  <br>

  <!-- open -->
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Articulos que se encuentran en ruta
      </mat-panel-title>
      <mat-panel-description>
        {{ 'Total: ' + dataSource2.data.length + '/' + lstItems.length }}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <!-- <mat-form-field>
    <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" />
  </mat-form-field> -->

    <table mat-table [dataSource]="dataSource2" class="mat-elevation-z8" matSort *ngIf="dataSource2.data.length > 0">
      <ng-container matColumnDef="item">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Articulo</th>
        <td mat-cell *matCellDef="let element">{{ current.product.name + " (Lote: " + current.number + ", Articulo: " +
          element.number + ")"}}</td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
        <td mat-cell *matCellDef="let element">{{ element.register?.state.name }}</td>
      </ng-container>

      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ubicación</th>
        <td mat-cell *matCellDef="let element">{{ element.register?.location.name}}</td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
        <td mat-cell *matCellDef="let element">{{ element.register?.user.name }}</td>
      </ng-container>

      <ng-container matColumnDef="date_time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
        <td mat-cell *matCellDef="let element">{{ element.register?.date_time | date :'yyyy-dd-MM hh-mm-ss' }}</td>
      </ng-container>

      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef>Opciones</th>
        <td mat-cell *matCellDef="let element">
          <button mat-stroked-button color="accent" (click)="onShow(element)">
            <mat-icon>qr_code_2</mat-icon>
          </button>
          <button mat-stroked-button color="prym" (click)="onShowRegister(element)">
            <mat-icon>segment</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div [hidden]="dataSource2.data.length > 0">
      <h3>No hay articulos en ruta</h3>
    </div>

    <!-- <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator> -->
  </mat-expansion-panel>

</div>
