<app-spinner *ngIf="isLoading"></app-spinner>

<div [hidden]="isLoading">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" />
  </mat-form-field>

  <div class="container-btn-new">
    <button mat-stroked-button (click)="onNew()">
      <mat-icon>note_add</mat-icon>
      Nuevo Usuario
    </button>
  </div>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Apellido</th>
      <td mat-cell *matCellDef="let element">{{ element.last_name }}</td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
      <td mat-cell *matCellDef="let element">{{ element.state ? "Activo": "Inactivo" }}</td>
    </ng-container>

    <ng-container matColumnDef="options">

      <th mat-header-cell *matHeaderCellDef>Options</th>
      <td mat-cell *matCellDef="let element">
        <button mat-stroked-button color="accent" (click)="onEdit(element)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-stroked-button color="warn" (click)="onDelete(element)" *ngIf="element.id != 1">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
</div>
