<div class="login-page">
  <div class="form">
    <form class="register-form">
      <input type="text" placeholder="Correo" />
      <input type="password" placeholder="Contraseña" />
      <input type="text" placeholder="email address" />
      <button>create</button>
      <p class="message">Already registered? <a href="#">Sign In</a></p>
    </form>
    <form class="login-form" [formGroup]="loginForm" (ngSubmit)="login()">
      <input type="email" formControlName="email" placeholder="Correo" matInput required />
      <mat-error *ngIf="isValidField('email')">
        {{ getErrorMessage("email") }}
      </mat-error>
      <input type="password" formControlName="password" placeholder="Contraseña" matInput minLength="8" required />
      <mat-error *ngIf="isValidField('password')">
        {{ getErrorMessage("password") }}
      </mat-error>
      <br>
      <br>
      <button type="submit">Iniciar Sesión</button>
      <!-- <p class="message">Not registered? <a href="#">Create an account</a></p> -->
    </form>
  </div>
</div>
