<app-spinner *ngIf="isLoading"></app-spinner>

<div [hidden]="isLoading">
  <!-- <mat-form-field>
    <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" />
  </mat-form-field> -->

  <div class="container-btn-new">
    <!--     <button mat-stroked-button (click)="onNewItem()">
      <mat-icon>note_add</mat-icon>
      Nuevo Usuario
    </button> -->
  </div>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
      <td mat-cell *matCellDef="let element">{{ element.state.name }}</td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Ubicación</th>
      <td mat-cell *matCellDef="let element">{{ element.location.name}}</td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
      <td mat-cell *matCellDef="let element">{{ element.user.name }}</td>
    </ng-container>

    <ng-container matColumnDef="date_time">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
      <td mat-cell *matCellDef="let element">{{ element.date_time | date :'yyyy-dd-MM hh-mm-ss' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
</div>
