<app-spinner *ngIf="isLoading; else content"></app-spinner>

<ng-template #content>
<div class="row">
  <div class="col-sm-12 col-md-12 mx-auto">
    <div class="card">
      <div class="card-body">
        <h3 class="text-center">Nuevo Abono</h3>
        <form [formGroup]="form" (ngSubmit)="onCreate()">
          <mat-form-field class="full-width-input">
            <input
              type="text"
              formControlName="bank"
              matInput
              placeholder="Banco"
              required
            />
            <mat-error *ngIf="isValidField('bank')">
              {{ getErrorMessage("bank") }}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="full-width-input">
            <input
              type="number"
              formControlName="amount"
              matInput
              placeholder="Cantidad"
              required
            />
            <mat-error *ngIf="isValidField('amount')">
              {{ getErrorMessage("amount") }}
            </mat-error>
          </mat-form-field>

          <button type="submit" class="btn btn-primary btn-block mt-3">
            Guardar
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
</ng-template>
