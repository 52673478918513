<app-spinner *ngIf="isLoading"></app-spinner>

<div [hidden]="isLoading">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" />
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
      <td mat-cell *matCellDef="let element">{{ element.product.name + " (Lote: " + element.number + ")" }}</td>
    </ng-container>

    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Producto</th>
      <td mat-cell *matCellDef="let element">{{ element.product.name }}</td>
    </ng-container>

    <ng-container matColumnDef="warehouse">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Bodega</th>
      <td mat-cell *matCellDef="let element">{{ element.warehouse.name }}</td>
    </ng-container>
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
      <td mat-cell *matCellDef="let element">{{ element.user.name }}</td>
    </ng-container>
    <ng-container matColumnDef="size">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Tamaño</th>
      <td mat-cell *matCellDef="let element">{{ element.size }}</td>
    </ng-container>
    <ng-container matColumnDef="date_time">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
      <td mat-cell *matCellDef="let element">{{ element.date_time | date :'yyyy-MM-dd hh-mm-ss' }}</td>
    </ng-container>

    <ng-container matColumnDef="options">

      <th mat-header-cell *matHeaderCellDef>Opciones</th>
      <td mat-cell *matCellDef="let element">
        <!-- <button mat-stroked-button color="accent" (click)="onQR(element)">
          <mat-icon>qr_code_2</mat-icon>
        </button> -->
        <button mat-stroked-button color="primary" (click)="onReport(element)">
          <mat-icon>summarize</mat-icon>
        </button>
        <button mat-stroked-button color="accent" (click)="onChangeState(element)">
          <mat-icon>published_with_changes</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
</div>
