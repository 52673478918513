<app-spinner *ngIf="isLoading; else content"></app-spinner>

<ng-template #content>
  <button type="button" class="btn btn-secondary"
    onclick="printJS({printable: 'printJS-form', type: 'html', scanStyles: false })">
    Imprimir
  </button>
  <br>
  <br>
  <br>
  <div id="printJS-form">
    <div class="row" style="display: flex;
    flex-wrap: wrap; justify-content: center;">
      <div *ngFor="let item of lstItems" style="width: 20.00%; padding-top: 10px">
        <div style="display: flex;
        justify-content: center;">
          <img src="{{item.qr}}" alt="" width="70%" style="margin: auto;">
        </div>
        <dir style="width: 100%; margin: 0; padding: 0; padding-top: 5px; padding-bottom: 10px; font-size: 11px; color: black; font-weight: bold; text-align: center;">
          {{ current.product.name}} <br> {{ "(Lote: " + current.number + ", Articulo: " + item.number + ")"}}
        </dir>
      </div>
    </div>
  </div>
</ng-template>
