import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InventarioService } from 'src/app/services/inventarioService/inventario.service';

@Component({
  selector: 'app-show-item',
  templateUrl: './show-item.component.html',
  styleUrls: ['./show-item.component.scss'],
})
export class ShowItemComponent implements OnInit {
  isLoading = true;
  currentItem: any = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ws: InventarioService,
    private formBuilder: FormBuilder
  ) {}

  async ngOnInit(): Promise<void> {
    let id = this.route.snapshot.params.id;
    const datos = await new Promise<any>((resolve) =>
      this.ws.getItem(id).subscribe((translated) => {
        resolve(translated);
      })
    );

    this.currentItem = datos.res;
    this.isLoading = false;
  }
}
