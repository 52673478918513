import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InventarioService } from 'src/app/services/inventarioService/inventario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-warehouse',
  templateUrl: './edit-warehouse.component.html',
  styleUrls: ['./edit-warehouse.component.scss'],
})
export class EditWarehouseComponent implements OnInit {
  public current;

  public form;
  public lstLocations;

  isLoading = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ws: InventarioService
  ) {}

  async ngOnInit(): Promise<void> {
    this.form = new FormGroup({
      location_id: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      state: new FormControl(true),
    });

    let id = this.route.snapshot.params.id;
    const datos = await new Promise<any>((resolve) =>
      this.ws.getWarehouse(id).subscribe((translated) => {
        resolve(translated);
      })
    );

    if (datos.success) {
      this.current = datos.res;

      const locations = await new Promise<any>((resolve) =>
        this.ws.getLocations().subscribe((translated) => {
          resolve(translated);
        })
      );

      if (locations.success) {
        this.lstLocations = locations.res;
      }

      this.initValuesForm();

      this.isLoading = false;
    }
  }

  private initValuesForm(): void {
    this.form = new FormGroup({
      location_id: new FormControl(parseInt(this.current.location_id), [
        Validators.required,
      ]),
      name: new FormControl(this.current.name, [Validators.required]),
      state: new FormControl(true),
    });
  }

  async onEdit() {
    if (this.form.valid) {
      let data = this.form.value;
      data.id = this.current.id;
      data['fecha'] = new Date();
      const datos = await new Promise<any>((resolve) =>
        this.ws.putWarehouse(data).subscribe((translated) => {
          resolve(translated);
        })
      );

      if (datos.success) {
        this.router.navigate(['admin/warehouses']);
      } else {
        Swal.fire({
          title: 'Guardar',
          text: 'Error al guardar',
          icon: 'error',
        });
      }
    } else {
      Swal.fire('Guardar', 'Llene todos los campos', 'info');
    }
  }

  getErrorMessage(field: string): string {
    let message;
    if (this.form.get(field).errors.required) {
      message = 'Debes ingresar un valor';
    } else if (this.form.get(field).hasError('email')) {
      message = 'No es un email valido';
    } else if (this.form.get(field).hasError('minlength')) {
      message = 'Este campo debe tener más de 6 caracteres';
    }
    return message;
  }

  isValidField(field: string): boolean {
    return (
      (this.form.get(field).touched || this.form.get(field).dirty) &&
      !this.form.get(field).valid
    );
  }
}
