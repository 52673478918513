import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InventarioService } from 'src/app/services/inventarioService/inventario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  public current;
  public role;
  public form;
  public lstLocations;
  public lstRoles;

  isLoading = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ws: InventarioService
  ) {}

  async ngOnInit(): Promise<void> {
    this.form = new FormGroup({
      location_id: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.minLength(8),
      ]),
      state: new FormControl(true),
      role_id: new FormControl('', [Validators.required]),
    });

    let id = this.route.snapshot.params.id;
    const datos = await new Promise<any>((resolve) =>
      this.ws.getUser(id).subscribe((translated) => {
        resolve(translated);
      })
    );

    if (datos.success) {
      this.current = datos.res;

      const locations = await new Promise<any>((resolve) =>
        this.ws.getLocations().subscribe((translated) => {
          resolve(translated);
        })
      );

      if (locations.success) {
        this.lstLocations = locations.res;
      }

      const roles = await new Promise<any>((resolve) =>
        this.ws.getRoles().subscribe((translated) => {
          resolve(translated);
        })
      );

      if (roles.success) {
        this.lstRoles = roles.res;
      }

      const role = await new Promise<any>((resolve) =>
        this.ws.getUserRoles(id).subscribe((translated) => {
          resolve(translated);
        })
      );

      if (role.success) {
        this.role = role.res[0];
      }

      this.initValuesForm();

      this.isLoading = false;
    }
  }

  private initValuesForm(): void {
    this.form = new FormGroup({
      location_id: new FormControl(parseInt(this.current.location_id), [
        Validators.required,
      ]),
      name: new FormControl(this.current.name, [Validators.required]),
      last_name: new FormControl(this.current.last_name, [Validators.required]),
      email: new FormControl(this.current.email, [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl(this.current.password, [
        Validators.minLength(8),
      ]),
      state: new FormControl(this.current.state, [Validators.required]),
      role_id: new FormControl(parseInt(this.role.id), [Validators.required]),
    });
  }

  async onEdit() {
    if (this.form.valid) {
      let data = this.form.value;
      data.id = this.current.id;
      data['fecha'] = new Date();
      const datos = await new Promise<any>((resolve) =>
        this.ws.putUser(data).subscribe((translated) => {
          resolve(translated);
        })
      );

      if (datos.success) {
        await new Promise<any>((resolve) =>
          this.ws
            .postUserRole({ role_id: data.role_id, user_id: this.current.id })
            .subscribe((translated) => {
              resolve(translated);
            })
        );
        this.router.navigate(['admin/users']);
      } else {
        Swal.fire({
          title: 'Guardar',
          text: 'Error al guardar, el correo no puede estar repetido',
          icon: 'error',
        });
      }
    } else {
      Swal.fire('Guardar', 'Llene todos los campos', 'info');
    }
  }

  getErrorMessage(field: string): string {
    let message;
    if (this.form.get(field).errors.required) {
      message = 'Debes ingresar un valor';
    } else if (this.form.get(field).hasError('email')) {
      message = 'No es un email valido';
    } else if (this.form.get(field).hasError('minlength')) {
      message = 'Este campo debe tener más de 6 caracteres';
    }
    return message;
  }

  isValidField(field: string): boolean {
    return (
      (this.form.get(field).touched || this.form.get(field).dirty) &&
      !this.form.get(field).valid
    );
  }
}
