import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InventarioService {
  private url = environment.apiUrl;

  constructor(private _http: HttpClient) {}

  public login(usuario: any) {
    let json = JSON.stringify(usuario);
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.post(this.url + 'login/admin/', json, {
      headers: header,
    });
  }

  public loginData(usuario: any) {
    let json = JSON.stringify(usuario);
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.post(this.url + 'login/data/', json, {
      headers: header,
    });
  }

  //
  public getLocations() {
    return this._http.get(this.url + 'locations/');
  }

  //
  public getUsers() {
    return this._http.get(this.url + 'users/');
  }

  public getUser(id: any) {
    return this._http.get(this.url + 'users/' + id);
  }

  public postUser(usersData: any) {
    let json = JSON.stringify(usersData);
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.post(this.url + 'users/', json, { headers: header });
  }

  public putUser(usersData: any) {
    let json = JSON.stringify(usersData);
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.put(this.url + 'users/' + usersData.id, json, {
      headers: header,
    });
  }

  public deleteUser(id: any) {
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.delete(this.url + 'users/' + id, { headers: header });
  }

  public getUserRoles(id: any) {
    return this._http.get(this.url + 'users/roles/' + id);
  }

  public postUserRole(userRole: any) {
    let json = JSON.stringify(userRole);
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.post(this.url + 'users/role', json, {
      headers: header,
    });
  }

  //
  public getRoles() {
    return this._http.get(this.url + 'roles/');
  }

  //
  public getProducts() {
    return this._http.get(this.url + 'products/');
  }

  public getProduct(id: any) {
    return this._http.get(this.url + 'products/' + id);
  }

  public postProduct(productsData: any) {
    let json = JSON.stringify(productsData);
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.post(this.url + 'products/', json, { headers: header });
  }

  public putProduct(productsData: any) {
    let json = JSON.stringify(productsData);
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.put(this.url + 'products/' + productsData.id, json, {
      headers: header,
    });
  }

  public deleteProduct(id: any) {
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.delete(this.url + 'products/' + id, { headers: header });
  }

  //
  public getWarehouses() {
    return this._http.get(this.url + 'warehouses/');
  }

  public getWarehouse(id: any) {
    return this._http.get(this.url + 'warehouses/' + id);
  }

  public postWarehouse(warehousesData: any) {
    let json = JSON.stringify(warehousesData);
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.post(this.url + 'warehouses/', json, { headers: header });
  }

  public putWarehouse(warehousesData: any) {
    let json = JSON.stringify(warehousesData);
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.put(this.url + 'warehouses/' + warehousesData.id, json, {
      headers: header,
    });
  }

  public deleteWarehouse(id: any) {
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.delete(this.url + 'warehouses/' + id, {
      headers: header,
    });
  }

  //
  public getBatchs() {
    return this._http.get(this.url + 'batchs/');
  }

  public getBatchsInactive() {
    return this._http.get(this.url + 'batchs/inactive/');
  }

  public getBatch(id: any) {
    return this._http.get(this.url + 'batchs/' + id);
  }

  public postBatch(batchsData: any) {
    let json = JSON.stringify(batchsData);
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.post(this.url + 'batchs/', json, { headers: header });
  }

  public putBatch(batchsData: any) {
    let json = JSON.stringify(batchsData);
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.put(this.url + 'batchs/' + batchsData.id, json, {
      headers: header,
    });
  }

  public deleteBatch(id: any) {
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.delete(this.url + 'batchs/' + id, { headers: header });
  }

  public getBatchsItems(id: any) {
    return this._http.get(this.url + 'batchs/items/' + id);
  }

  //
  public getItems() {
    return this._http.get(this.url + 'items/');
  }

  public getItem(id: any) {
    return this._http.get(this.url + 'items/' + id);
  }

  //
  public postRegister(register: any) {
    let json = JSON.stringify(register);
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.post(this.url + 'registers/', json, { headers: header });
  }

  public getRegistersItem(id: any) {
    return this._http.get(this.url + 'registers/item/' + id);
  }

  public getSales() {
    return this._http.get(this.url + 'sales/');
  }

  public getSale(id: any) {
    return this._http.get(this.url + 'sales/' + id);
  }

  public deleteSale(id: any) {
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.delete(this.url + 'sales/' + id, { headers: header });
  }

  public getSaleItems(id: any) {
    return this._http.get(this.url + 'sales/items/' + id);
  }

  public getSalePayments(id: any) {
    return this._http.get(this.url + 'sales/payments/' + id);
  }

  public setSalePayments(id: any, payments: any) {
    let json = JSON.stringify(payments);
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.put(this.url + 'sales/payments/' + id, json, {
      headers: header,
    });
  }

  public getPayments() {
    return this._http.get(this.url + 'payments/');
  }

  public getPayment(id: any) {
    return this._http.get(this.url + 'payments/' + id);
  }

  public postPayment(payment: any) {
    let json = JSON.stringify(payment);
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.post(this.url + 'payments/', json, { headers: header });
  }

  public putPayment(payment: any) {
    let json = JSON.stringify(payment);
    let header = new HttpHeaders().set(
      'Content-Type',
      'application/json;  charset=utf-8'
    );
    return this._http.put(this.url + 'payments/' + payment.id, json, {
      headers: header,
    });
  }

  //
  public getRoutes() {
    return this._http.get(this.url + 'routes/');
  }
}
