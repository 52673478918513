<app-spinner *ngIf="isLoading"></app-spinner>

<div [hidden]="isLoading">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" />
  </mat-form-field>

  <div class="container-btn-new">
<!--     <button mat-stroked-button (click)="onNewItem()">
      <mat-icon>note_add</mat-icon>
      Nuevo Usuario
    </button> -->
  </div>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
    <ng-container matColumnDef="item">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Articulo</th>
      <td mat-cell *matCellDef="let element">{{ element.product.name + " (Lote: " + element.batch.number + ", Articulo: " + element.number + ")"}}</td>
    </ng-container>

    <ng-container matColumnDef="options">

      <th mat-header-cell *matHeaderCellDef>Options</th>
      <td mat-cell *matCellDef="let element">
<!--         <button mat-stroked-button color="prym" (click)="onRolesMessage(element)">
          <mat-icon>group</mat-icon>
        </button>
        <button mat-stroked-button color="accent" (click)="onEditMessage(element)">
          <mat-icon>edit</mat-icon>
        </button> -->
        <button mat-stroked-button color="accent" (click)="onShow(element)">
          <mat-icon>qr_code_2</mat-icon>
        </button>
        <button mat-stroked-button color="prym" (click)="onShowRegister(element)">
          <mat-icon>segment</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
</div>
