import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public userData: any = {};

  public role:any = {};

  constructor() { }

  valSesion() {
    if (
      localStorage.getItem('currentUser') != null &&
      localStorage.getItem('currentUser') != ''
    ) {
      return true;
    }
    return false;
  }
}

