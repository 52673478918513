import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { InventarioService } from 'src/app/services/inventarioService/inventario.service';
import { SharedService } from 'src/app/services/sharedService/shared.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm: any;

  constructor(
    private router: Router,
    private ws: InventarioService,
    private ss: SharedService
  ) {
    if (this.ss.valSesion()) {
      this.router.navigate(['admin']);
    }
  }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
    });
  }

  async login() {
    if (this.loginForm.valid) {
      const datos = await new Promise<any>((resolve) =>
        this.ws.login(this.loginForm.value).subscribe((translated) => {
          resolve(translated);
        })
      );

      if (datos.login) {
        localStorage.setItem('currentUser', JSON.stringify(datos.res));
        this.router.navigate(['admin']);
      } else {
        Swal.fire('Iniciar sesión', datos.message, 'warning');
      }
    } else {
      Swal.fire('Iniciar sesión', 'Llene todos los campos', 'info');
    }
  }

  getErrorMessage(field: string): string {
    let message = '';
    if (this.loginForm.get(field).errors.required) {
      message = 'Ingresar un valor';
    } else if (this.loginForm.get(field).hasError('email')) {
      message = 'No es un email valido';
    } else if (this.loginForm.get(field).hasError('minlength')) {
      message = 'Ingrese mínimo de 8 caracteres';
    }
    return message;
  }

  isValidField(field: string): boolean {
    return (
      (this.loginForm.get(field).touched || this.loginForm.submitted) &&
      !this.loginForm.get(field).valid
    );
  }
}
