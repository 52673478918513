import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InventarioService } from './services/inventarioService/inventario.service';
import { SharedService } from './services/sharedService/shared.service';
import { MaterialModule } from './material.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { ContainerAdminComponent } from './components/container-admin/container-admin.component';
import { UsersComponent } from './components/users/users.component';
import { NewUserComponent } from './components/users/new-user/new-user.component';
import { EditUserComponent } from './components/users/edit-user/edit-user.component';
import { ProductsComponent } from './components/products/products.component';
import { NewProductComponent } from './components/products/new-product/new-product.component';
import { EditProductComponent } from './components/products/edit-product/edit-product.component';
import { WarehousesComponent } from './components/warehouses/warehouses.component';
import { NewWarehouseComponent } from './components/warehouses/new-warehouse/new-warehouse.component';
import { EditWarehouseComponent } from './components/warehouses/edit-warehouse/edit-warehouse.component';
import { BatchsComponent } from './components/batchs/batchs.component';
import { NewBatchComponent } from './components/batchs/new-batch/new-batch.component';
import { EditBatchComponent } from './components/batchs/edit-batch/edit-batch.component';
import { ItemsComponent } from './components/items/items.component';
import { NewItemComponent } from './components/items/new-item/new-item.component';
import { EditItemComponent } from './components/items/edit-item/edit-item.component';
import { LoginComponent } from './components/auch/login/login.component';
import { ShowItemComponent } from './components/items/show-item/show-item.component';
import { ShowRegisterComponent } from './components/items/show-register/show-register.component';
import { SalesComponent } from './components/sales/sales.component';
import { EditSaleComponent } from './components/sales/edit-sale/edit-sale.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { SalePaymentsComponent } from './components/sales/sale-payments/sale-payments.component';
import { NewPaymentComponent } from './components/sales/sale-payments/new-payment/new-payment.component';
import { EditPaymentComponent } from './components/sales/sale-payments/edit-payment/edit-payment.component';
import { QrPrintComponent } from './components/batchs/qr-print/qr-print.component';
import { ReportBatchComponent } from './components/batchs/report-batch/report-batch.component';
import { BatchsInactiveComponent } from './components/batchs-inactive/batchs-inactive.component';

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    ContainerAdminComponent,
    UsersComponent,
    NewUserComponent,
    EditUserComponent,
    ProductsComponent,
    NewProductComponent,
    EditProductComponent,
    WarehousesComponent,
    NewWarehouseComponent,
    EditWarehouseComponent,
    BatchsComponent,
    NewBatchComponent,
    EditBatchComponent,
    ItemsComponent,
    NewItemComponent,
    EditItemComponent,
    LoginComponent,
    ShowItemComponent,
    ShowRegisterComponent,
    SalesComponent,
    EditSaleComponent,
    PaymentsComponent,
    SalePaymentsComponent,
    NewPaymentComponent,
    EditPaymentComponent,
    QrPrintComponent,
    ReportBatchComponent,
    BatchsInactiveComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
  ],
  providers: [SharedService, InventarioService],
  bootstrap: [AppComponent]
})
export class AppModule { }
