import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InventarioService } from 'src/app/services/inventarioService/inventario.service';

@Component({
  selector: 'app-qr-print',
  templateUrl: './qr-print.component.html',
  styleUrls: ['./qr-print.component.scss'],
})
export class QrPrintComponent implements OnInit {
  isLoading = true;

  current;
  lstItems: any = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ws: InventarioService,
    private formBuilder: FormBuilder
  ) {}

  async ngOnInit(): Promise<void> {
    let id = this.route.snapshot.params.id;

    const branch = await new Promise<any>((resolve) =>
      this.ws.getBatch(id).subscribe((translated) => {
        resolve(translated);
      })
    );

    const datos = await new Promise<any>((resolve) =>
      this.ws.getBatchsItems(id).subscribe((translated) => {
        resolve(translated);
      })
    );

    this.current = branch.res;
    this.lstItems = datos.res;
    this.isLoading = false;
  }
}
