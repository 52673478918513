import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { InventarioService } from 'src/app/services/inventarioService/inventario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-batch',
  templateUrl: './new-batch.component.html',
  styleUrls: ['./new-batch.component.scss'],
})
export class NewBatchComponent implements OnInit {
  public form;
  lstProducts = [];
  lstRoutes = [];
  lstWarehouses = [];
  lstWarehousesDestiny = [];

  isLoading = false;

  constructor(
    private router: Router,
    private ws: InventarioService,
    private formBuilder: FormBuilder
  ) {}

  async ngOnInit(): Promise<void> {
    this.form = new FormGroup({
      product_id: new FormControl('', [Validators.required]),
      /* warehouse_id: new FormControl('', [Validators.required]),
      warehouse_destiny_id: new FormControl('', [Validators.required]), */
      route_id: new FormControl('', [Validators.required]),
      size: new FormControl('', [Validators.required]),
    });

    const lstProducts = await new Promise<any>((resolve) =>
      this.ws.getProducts().subscribe((translated) => {
        resolve(translated);
      })
    );

    if (lstProducts.success) {
      this.lstProducts = lstProducts.res;
    }

    const routes = await new Promise<any>((resolve) =>
      this.ws.getRoutes().subscribe((translated) => {
        resolve(translated);
      })
    );

    if (routes.success) {
      this.lstRoutes = routes.res;
    }

    const warehouses = await new Promise<any>((resolve) =>
      this.ws.getWarehouses().subscribe((translated) => {
        resolve(translated);
      })
    );

    if (warehouses.success) {
      this.lstWarehouses = warehouses.res.filter((item) => item.id === 1);
      this.lstWarehousesDestiny = warehouses.res.filter(
        (item) => item.id === 3 || item.id === 4
      );
    }
  }

  async onCreate() {
    if (this.form.valid) {
      const user = JSON.parse(localStorage.getItem('currentUser'));
      let data = this.form.value;
      data.user_id = user.id;
      data['state'] = true;
      data['date_time'] = new Date();
      const datos = await new Promise<any>((resolve) =>
        this.ws.postBatch(data).subscribe((translated) => {
          resolve(translated);
        })
      );

      if (datos.success) {
        this.router.navigate(['admin/batchs']);
      } else {
        Swal.fire({
          title: 'Guardar',
          text: 'Error al guardar',
          icon: 'error',
        });
      }
    } else {
      Swal.fire('Guardar', 'Llene todos los campos', 'info');
    }
  }

  getErrorMessage(field: string): string {
    let message;
    if (this.form.get(field).errors.required) {
      message = 'Debes ingresar un valor';
    } else if (this.form.get(field).hasError('email')) {
      message = 'No es un email valido';
    } else if (this.form.get(field).hasError('minlength')) {
      message = 'Este campo debe tener más de 6 caracteres';
    }
    return message;
  }

  isValidField(field: string): boolean {
    return (
      (this.form.get(field).touched || this.form.get(field).dirty) &&
      !this.form.get(field).valid
    );
  }
}
