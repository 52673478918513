import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InventarioService } from 'src/app/services/inventarioService/inventario.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss'],
})
export class EditProductComponent implements OnInit {
  public current;

  public form;

  isLoading = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ws: InventarioService
  ) {}

  async ngOnInit(): Promise<void> {
    let id = this.route.snapshot.params.id;
    const datos = await new Promise<any>((resolve) =>
      this.ws.getProduct(id).subscribe((translated) => {
        resolve(translated);
      })
    );

    if (datos.success) {
      this.current = datos.res;
      this.initValuesForm();
      this.isLoading = false;
    }
  }

  private initValuesForm(): void {
    this.form = new FormGroup({
      name: new FormControl(this.current.name, [Validators.required]),
      description: new FormControl(this.current.description, [
        Validators.required,
      ]),
      price: new FormControl(this.current.price, [Validators.required]),
      state: new FormControl(this.current.state),
    });
  }

  public async onEdit() {
    if (this.form.valid) {
      let data = this.form.value;
      data['id'] = this.current.id;
      console.log(data);
      const datos = await new Promise<any>((resolve) =>
        this.ws.putProduct(data).subscribe((translated) => {
          resolve(translated);
        })
      );

      if (datos.success) {
        this.router.navigate(['admin/products']);
      }else {
        Swal.fire({
          title: 'Editar',
          text: 'Error al editar',
          icon: 'error',
        });
      }
    } else {
      Swal.fire('Editar', 'Llene todos los campos', 'info');
    }
  }

  getErrorMessage(field: string): string {
    let message;
    if (this.form.get(field).errors.required) {
      message = 'Debes ingresar un valor';
    } else if (this.form.get(field).hasError('email')) {
      message = 'No es un email valido';
    } else if (this.form.get(field).hasError('minlength')) {
      message = 'Este campo debe tener más de 6 caracteres';
    }
    return message;
  }

  isValidField(field: string): boolean {
    return (
      (this.form.get(field).touched ||
        this.form.get(field).dirty) &&
      !this.form.get(field).valid
    );
  }
}
