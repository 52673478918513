<app-spinner *ngIf="isLoading"></app-spinner>

<div [hidden]="isLoading">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event)" placeholder="Buscar" />
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="sale_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Venta</th>
      <td mat-cell *matCellDef="let element">{{ element.sale_id }}</td>
    </ng-container>

    <ng-container matColumnDef="bank">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Banco</th>
      <td mat-cell *matCellDef="let element">{{ element.bank }}</td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Cantidad</th>
      <td mat-cell *matCellDef="let element">{{ element.amount }}</td>
    </ng-container>

    <ng-container matColumnDef="date_time">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
      <td mat-cell *matCellDef="let element">{{ element.date_time | date :'yyyy-MM-dd hh-mm-ss' }}</td>
    </ng-container>

    <ng-container matColumnDef="options">

      <th mat-header-cell *matHeaderCellDef>Opciones</th>
      <td mat-cell *matCellDef="let element">
        <button mat-stroked-button color="accent" (click)="onEdit(element)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-stroked-button color="warn" (click)="onDelete(element)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
</div>
