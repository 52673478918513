<app-spinner *ngIf="isLoading; else content"></app-spinner>

<ng-template #content>
  <div class="row">
    <div class="col-sm-12 col-md-12 mx-auto">
      <div class="card">
        <div class="card-body">
          <h3 class="text-center">Editar Lote</h3>
          <form [formGroup]="form" (ngSubmit)="onCreate()">
            <mat-form-field class="full-width-input">
              <mat-label>Producto</mat-label>
              <mat-select formControlName="product_id">
                <mat-option *ngFor="let product of lstProducts" [value]="product.id">
                  {{ product.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width-input">
              <mat-label>Bodega</mat-label>
              <mat-select formControlName="warehouse_id">
                <mat-option *ngFor="let warehouses of lstWarehouses" [value]="warehouses.id">
                  {{ warehouses.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-width-input">
              <input
                type="number"
                formControlName="size"
                matInput
                placeholder="Tamaño"
                required
              />
              <mat-error *ngIf="isValidField('size')">
                {{ getErrorMessage("size") }}
              </mat-error>
            </mat-form-field>

            <button type="submit" class="btn btn-primary btn-block mt-3">
              Guardar
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
