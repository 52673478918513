import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { InventarioService } from 'src/app/services/inventarioService/inventario.service';
import { SharedService } from 'src/app/services/sharedService/shared.service';

@Component({
  selector: 'app-container-admin',
  templateUrl: './container-admin.component.html',
  styleUrls: ['./container-admin.component.scss'],
})
export class ContainerAdminComponent implements OnInit {
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;

  public isLogin = false;

  public userData: any = {};

  public role:any = {};

  constructor(
    private observer: BreakpointObserver,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private ws: InventarioService,
    private wsShared: SharedService
  ) {}

  async ngOnInit(): Promise<void> {
    const userData = JSON.parse(localStorage.getItem('currentUser'));
    if (userData.email) {
      const response = await new Promise<any>((resolve) =>
        this.ws
          .loginData({
            email: userData.email,
          })
          .subscribe((translated) => {
            resolve(translated);
          })
      );
      if (response.success && response.login) {
        this.userData = response.res;
        this.wsShared.userData = response.res;
        if (response.res.roles[0]) {
          this.role = response.res.roles[0];
          this.wsShared.role = response.res.roles[0];
        }
      } else {
        this.closeSesion();
      }
    } else {
      this.closeSesion();
    }
  }

  ngAfterViewInit() {
    this.observer.observe(['(max-width: 800px)']).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = 'over';
        this.sidenav.close();
      } else {
        this.sidenav.mode = 'side';
        this.sidenav.open();
      }
    });
    this.cdRef.detectChanges();
  }

  closeSesion() {
    localStorage.setItem('currentUser', '');
    this.router.navigate(['home']);
  }
}
