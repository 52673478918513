import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { InventarioService } from 'src/app/services/inventarioService/inventario.service';
import { SharedService } from 'src/app/services/sharedService/shared.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-batchs-inactive',
  templateUrl: './batchs-inactive.component.html',
  styleUrls: ['./batchs-inactive.component.scss'],
})
export class BatchsInactiveComponent implements OnInit {
  isLoading = true;
  displayedColumns: string[] = [
    'name',
    'product',
    'warehouse',
    'user',
    'size',
    /* 'state', */
    'date_time',
    'options',
  ];
  dataSource = new MatTableDataSource();

  constructor(
    private router: Router,
    private ws: InventarioService,
    public wsShared: SharedService,
    public dialog: MatDialog
  ) {}

  applyFilter(filterValue: any) {
    this.dataSource.filter = filterValue.target.value.trim().toLowerCase();
  }

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  async ngOnInit() {
    const datos = await new Promise<any>((resolve) =>
      this.ws.getBatchsInactive().subscribe((translated) => {
        resolve(translated);
      })
    );

    if (datos.success) {
      this.dataSource.data = datos.res;
      this.isLoading = false;
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  hideSpinner() {
    this.isLoading = false;
  }

  onQR(batch: any) {
    this.router.navigate(['admin/batchs/qr/' + batch.id]);
  }

  onReport(batch: any) {
    this.router.navigate(['admin/batchs/report/' + batch.id]);
  }

  onChangeState(batch: any) {
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Está a punto de cambiar el estado del lote',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, cambiar',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.value) {
        batch.state = true;
        const datos = await new Promise<any>((resolve) =>
          this.ws.putBatch(batch).subscribe((translated) => {
            resolve(translated);
          })
        );

        if (datos.success) {
          Swal.fire('Cambiado', 'Estado del lote cambiado', 'success');
          this.ngOnInit();
        } else {
          Swal.fire('Error', 'Estado del lote no cambiado', 'warning');
        }
      }
    });
  }
}
